import { PrimaryButton } from '@components/ui';
import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import { getSignInUrl } from '../../lib/azure_ad';

export const LoginButton: FC = () => {
  const [signInUrl, setSignInUrl] = useState<string | null>(null);

  useEffect(() => {
    if (signInUrl != null) return;
    setSignInUrl(getSignInUrl());
  }, [signInUrl]);

  return (
    <Link href={signInUrl || ''}>
      <PrimaryButton>AzureADでログイン</PrimaryButton>
    </Link>
  );
};
