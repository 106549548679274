import { NextPage } from 'next';
import styled from 'styled-components';
import { Logo } from '@components/common';
import { LoginButton } from '@components/login/LoginButton';

const LoginContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.base.bg.main};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LogoWrapper = styled.div`
  margin-bottom: 48px;
`;

const Login: NextPage = () => {
  return (
    <LoginContainer>
      <LogoWrapper>
        <Logo size={1.5} />
      </LogoWrapper>
      <LoginButton />
    </LoginContainer>
  );
};

export default Login;
