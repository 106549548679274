export const getSignInUrl = (): string => {
  const MONEYFORWARD_CO_JP_AZURE_TENANT_ID =
    'f879bf71-a0d5-4249-b5b7-5f8a6fb6aa77';
  const url = new URL(
    `https://login.microsoftonline.com/${MONEYFORWARD_CO_JP_AZURE_TENANT_ID}/oauth2/v2.0/authorize`
  );
  url.searchParams.append(
    'client_id',
    `${process.env.NEXT_PUBLIC_AAD_CLIENT_ID}`
  );
  url.searchParams.append(
    'redirect_uri',
    `${window.location.origin}/auth/azureactivedirectory/callback`
  );
  url.searchParams.append('response_mode', 'query');
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('scope', 'openid profile email User.Read');
  // TODO: CSRF防止のためにstateを入れる
  // url.searchParams.append('state', 'TODO');
  return url.href;
};
